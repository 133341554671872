// @flow

import * as React from 'react';
import times from 'lodash/times';
import FontAwesomeIcon, {
  solidStar,
  regularStar,
  regularStarHalfStroke
} from 'common/components/FontAwesomeIcon';

import styles from './Rating.scss';

type Props = {|
  score?: number,
  max?: number
|};

export default function Rating({ score = 0, max = 5 }: Props): React.Node {
  const fraction = score - Math.floor(score);
  const fullStarCount = Math.floor(score);
  const emptyStarCount = max - Math.ceil(score);

  const partialStar = (() => {
    if (fraction === 0) {
      return null;
    } else if (fraction <= 0.25) {
      return (
        <FontAwesomeIcon
          icon={regularStar}
          className={styles.emptyStar}
          aria-label={__('shared.stars.empty')}
        />
      );
    } else if (fraction <= 0.75) {
      return (
        <FontAwesomeIcon
          icon={regularStarHalfStroke}
          className={styles.star}
          aria-label={__('shared.stars.half')}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={solidStar}
          className={styles.star}
          aria-label={__('shared.stars.full')}
        />
      );
    }
  })();

  return (
    <div className={styles.rating}>
      {times(fullStarCount, index => (
        <FontAwesomeIcon
          icon={solidStar}
          className={styles.star}
          aria-label={__('shared.stars.full')}
          key={`star-full:${index}`}
        />
      ))}
      {partialStar}
      {times(emptyStarCount, index => (
        <FontAwesomeIcon
          icon={regularStar}
          className={styles.emptyStar}
          aria-label={__('shared.stars.empty')}
          key={`star-empty:${index}`}
        />
      ))}
    </div>
  );
}
