// @flow

import * as React from 'react';

import FontAwesomeIcon, {
  solidCaretDown,
  solidCaretUp,
  solidChevronDown,
  solidChevronUp
} from 'common/components/FontAwesomeIcon';
import impossible from 'common/types/impossible';

type Props = {
  isOpen: boolean,
  icon?: 'chevron' | 'caret',
  ...
};

export default function AccordionStateIcon(props: Props): React.Node {
  const icon: 'chevron' | 'caret' = props.icon || 'chevron';
  switch (icon) {
    case 'chevron':
      if (props.isOpen) {
        return <FontAwesomeIcon icon={solidChevronUp} />;
      }
      return <FontAwesomeIcon icon={solidChevronDown} />;
    case 'caret':
      if (props.isOpen) {
        // eslint-disable-next-line no-undef
        return <FontAwesomeIcon icon={solidCaretUp} />;
      }
      return <FontAwesomeIcon icon={solidCaretDown} />;
    default:
      return impossible(icon);
  }
}
